import React, { forwardRef } from "react";

import {
  FormControl,
  OutlinedInput,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import dayjs from "dayjs";
import {FieldValues} from "react-hook-form";

import { DatePicker } from "../DatePicker";
import { ErrorTypography } from "../Typography";

export type TextInputProps = {
  label: string;
  type: string;
  required: boolean;
  error: boolean;
  errorText?: string;
  defaultValue?: string;
} & FieldValues;

export const TextFieldWithBirthDay = forwardRef<
  HTMLInputElement,
  TextInputProps
>(
  (
    {
      label,
      type,
      required,
      error,
      errorText,
      defaultValue,
      ...field
    },
    ref
  ) => {
    const todayString = dayjs(new Date()).format("YYYY-MM-DD");

    if(type === "date") {
      return (
        <FormControl variant="filled">
          <Stack spacing={1}>
            <Stack direction="row" spacing={0.5}>
              <Typography variant="bold14">{label}</Typography>
              {required && <Box color="alert.100">*</Box>}
            </Stack>
            <DatePicker 
              {...field}
              inputRef={field.ref}
              value={field.value ? dayjs(field.value).toDate() : null}
              defaultValue={defaultValue || ""}
              slotProps={{
                textField: {
                  name: field.name,
                  onBlur: field.onBlur,
                }
              }}
              onChange={(newValue) => {
                const date = dayjs(newValue).format("YYYY-MM-DD")
                field.onChange(date ?? "")
              }}
             />
            {error && errorText && <ErrorTypography errorText={errorText} />}
          </Stack>
        </FormControl>
      )
    }

    return (
      <FormControl variant="filled">
        <Stack spacing={1}>
          <Stack direction="row" spacing={0.5}>
            <Typography variant="bold14">{label}</Typography>
            {required && <Box color="alert.100">*</Box>}
          </Stack>

          <OutlinedInput
            type={type}
            ref={ref}
            defaultValue={defaultValue || ""}
            inputProps={{
              max: todayString,
              style: {
                padding: "13px 16px",
              },
            }}
            {...field}
          />
          {error && errorText && <ErrorTypography errorText={errorText} />}
        </Stack>
      </FormControl>
    );
  }
);
